<template>
  <v-container class="white px-0" fluid>
    <v-card flat>
      <product-form :item="item" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProductForm from "./ProductForm";

export default {
  name: "ProductEditor",
  components: { ProductForm },
  data() {
    return {
      item: {
        category: { section: null },
      },
    };
  },
  mounted() {
    if (this.editMode) {
      let item = this.getStoreProductById(this.idParam);
      if (item) {
        this.item = { ...item, unitType: item.unitType ? item.unitType.name : null };
      } else {
        this.loadStoreProductsByParams({ storeId: this.getStoreIdParam}).then(() => {
          item = this.getStoreProductById(this.idParam);
          this.item = { ...item, unitType: item.unitType ? item.unitType.name : null };
        });
      }
    }
  },
  computed: {
    ...mapGetters("products", ["getStoreProductById"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  methods: {
    ...mapActions("products", ["loadStoreProductsByParams", "saveStoreProduct"]),

    save(params) {
      const { item, editMode } = params;
      this.saveStoreProduct({ item, editMode }).then(() => {
        this.$router.go(-1);
      });
    },
    getItemDto(item) {
      return { ...item, categoryId: item.category ? item.category.id : null };
    },
  },
};
</script>
