import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('collapsable-card',{attrs:{"title":((_vm.$t('product.section')) + " / " + (_vm.$t('product.category'))),"flat":""}},[_c(VSheet,{staticClass:"pa-1"},[_c(VTextField,{attrs:{"label":"Search Categories...","flat":"","prepend-inner-icon":"mdi-magnify","solo":"","hide-details":"","clearable":"","clear-icon":"mdi-close","dense":"","background-color":"grey lighten-4"},model:{value:(_vm.categorySearch),callback:function ($$v) {_vm.categorySearch=$$v},expression:"categorySearch"}})],1),_c(VSheet,{staticClass:"pa-1",attrs:{"tile":"","height":"34"}},[_c(VChip,{attrs:{"small":"","label":"","dark":"","color":"grey lighten-1"}},[_vm._v(" "+_vm._s(_vm.selectedCategory && _vm.selectedCategory.section ? (_vm.selectedCategory.section ? _vm.selectedCategory.section.name + " / " : "") + _vm.selectedCategory.name : "")+" ")])],1),_c('div',{staticStyle:{"overflow-y":"scroll","height":"200px"}},[_c(VTreeview,{attrs:{"items":_vm.sectionsFilter,"item-children":"categories","active":_vm.active,"hoverable":"","activatable":"","open-on-click":"","transition":"","dense":""},on:{"update:active":[function($event){_vm.active=$event},function($event){return _vm.$emit('input', _vm.selectedCategory)}]},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var selected = ref.selected;
return [_c(VBtn,{attrs:{"icon":""}},[(selected)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-label-multiple ")]):_vm._e(),(!selected)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-label-multiple-outline ")]):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }