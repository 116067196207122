import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('p',{staticClass:"pt-4"},[_vm._v(_vm._s(_vm.params.body + ": [" + _vm.params.path + "]"))]),_c(VToolbar,{attrs:{"outlined":"","flat":"","short":"","dense":""}},[_c(VChipGroup,{staticStyle:{"max-width":"560px"}},_vm._l((this.params.selection),function(selected,i){return _c(VChip,{key:i,attrs:{"x-small":"","label":"","close":""},on:{"click:close":function($event){return _vm.removeSelection(i)}}},[_vm._v(" "+_vm._s(selected)+" ")])}),1),_c(VSpacer),_c(VDivider,{staticClass:"mr-1",attrs:{"vertical":""}}),_c(VBtn,{attrs:{"tile":"","depressed":"","icon":"","small":""},on:{"click":_vm.goToParent}},[_c(VIcon,[_vm._v("mdi-arrow-expand-up")])],1)],1),_c('br'),_c(VForm,{ref:"form"},[(!_vm.isLoadingFilesTree)?_c(VRow,_vm._l((_vm.getFiles),function(file,i){return _c(VCol,{key:i,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c(VCard,{staticClass:"text-center",attrs:{"tile":"","outlined":""}},[_c(VSheet,{staticClass:"pa-1",attrs:{"label":"","small":"","color":"grey lighten-3"}},[_vm._v(_vm._s(file.name))]),(!file.nodes && !_vm.isImage(file))?_c(VBtn,{attrs:{"x-large":"","color":"primary","icon":""},on:{"click":function($event){return _vm.addFile(file)}}},[_c(VIcon,[_vm._v(" mdi-file ")])],1):_vm._e(),(file.nodes)?_c(VBtn,{attrs:{"x-large":"","color":"orange","icon":""},on:{"click":function($event){return _vm.openDirectory(file)}}},[_c(VIcon,[_vm._v(" mdi-folder ")])],1):_vm._e(),(_vm.isImage(file))?_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":_vm.getPreview(file),"aspect-ratio":"1"},on:{"click":function($event){return _vm.addFile(file)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()],1)],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }