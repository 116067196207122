<template>
  <full-view :title="getTitle">
    <template v-slot:toolbar>
      <v-btn :disabled="!valid" large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-3" :style="getViewHeightStyle(36)">
        <v-form ref="form" v-model="valid" class="py-5 px-5">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.name"
                :counter="255"
                :rules="rules.name"
                :label="$t('general.name')"
                required
                dense
              />
              <v-textarea
                v-model="item.description"
                :counter="255"
                :label="$t('general.description')"
                rows="2"
                dense
              />
              <v-select
                v-model="item.unitTypeId"
                :items="this.getTypesByGroup('UNIT_TYPE')"
                :item-text="getLocale.lang === 'ar' ? 'nameAr' : 'name'"
                item-value="id"
                :rules="rules.unit"
                :label="$t('product.unit')"
                required
                dense
              ></v-select>
              <v-text-field
                v-model="item.unitPrice"
                :rules="rules.price"
                :label="$t('product.price')"
                type="number"
                required
                dense
              ></v-text-field>
              <v-text-field
                v-model="item.stock"
                :rules="rules.stock"
                :label="$t('product.stock')"
                type="number"
                required
                dense
              ></v-text-field>
              <v-combobox
                v-model="item.tags"
                :items="getTags"
                :label="$t('product.tags')"
                multiple
                append-icon="mdi-clipboard-list"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    small
                    class="pl-1 pr-1 ml-0 mr-1 mb-1"
                    label
                    @click="select"
                    @click:close="removeTag(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
              <v-text-field
                v-model="item.barcode"
                :rules="rules.barcode"
                :label="$t('product.barcode')"
                required
                dense
              ></v-text-field>
              <v-card flat outlined class="text-center">
                <vue-barcode
                  height="30"
                  with="60"
                  v-bind:value="item.barcode"
                />
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <category-selector
                :edit-mode="editMode"
                v-model="item.category"
                @input="updateCategoryId"
              />
            </v-col>
            <v-col cols="12" md="4" v-if="editMode">
              <product-images :edit-mode="editMode" v-model="item.images" />
            </v-col>
          </v-row>

          <br />

          <v-divider />
          <div class="d-flex">
            <v-btn
              :disabled="!valid"
              :dark="valid"
              color="success"
              class="mr-4"
              tile
              depressed
              @click="save"
            >
              <v-icon>mdi-content-save</v-icon>
              {{ $t("general.save") }}
            </v-btn>

            <v-btn color="primary" tile depressed @click="reset">
              <v-icon>mdi-undo-variant</v-icon>
              {{ $t("general.undo") }}
            </v-btn>

            <v-spacer />

            <v-btn tile depressed @click="$refs.form.validate()">
              <v-icon>mdi-check</v-icon>
              {{ $t("general.validate") }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import ProductImages from "./ProductImages";
import CategorySelector from "./CategorySelector";
import VueBarcode from "vue-barcode";

export default {
  name: "ProductForm",
  components: { FullView, ProductImages, CategorySelector, VueBarcode },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Product name is required",
          (v) => (!!v && v.length >= 5) || "Name must be at least 2 characters",
          (v) =>
            (!!v && v.length <= 256) ||
            "Must be less than or eaqual to 64 characters",
        ],
        unit: [(v) => !!v || "Unit type is required"],
        price: [
          (v) => !!v || "Unit-Price is required",
          (v) => v >= 0 || "Unit-Price must be grator than or equal to zero",
        ],
        stock: [
          (v) => !!v || "Stock is required",
          (v) => v >= 0 || "Stock must be grator than or equal to zero",
        ],
        section: [(v) => !!v || "Section is required"],
        category: [(v) => !!v || "Category is required"],
        barcode: [(v) => !!v || "Barcode is required"],
      },
      section: null,
    };
  },
  mounted() {
    Object.assign(this.originalState, this.item);
    this.loadTypes(true);
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("types", ["getTypesByGroup"]),

    getTitle() {
      if (this.editMode) {
        return this.$t("product.edit");
      } else {
        return this.$t("product.new");
      }
    },
    getTags() {
      const tags = this.getTypesByGroup("TAG");
      return this.notEmptyArray(tags) ? tags.map((m) => m.name) : [];
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),

    updateCategoryId() {
      this.item.categoryId = this.item.category ? this.item.category.id : 0;
    },
    save() {
      this.$emit("save", {
        item: {
          ...this.item,
          categoryId: this.item.category ? this.item.category.id : 0,
        },
        editMode: this.editMode,
        // tempAvatarCode: this.tempAvatarCode,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
    getImage(image) {
      return `${this.getApiBaseUrl}/files/?path=images/${image}`;
    },
    removeTag(tag) {
      if (this.notEmptyArray(this.item.tags)) {
        const t = this.item.tags.indexOf(tag);
        this.item.tags = this.item.tags.filter((f, i) => i !== t);
      }
    },
  },
};
</script>
