<template>
  <collapsable-card
    :title="`${$t('product.section')} / ${$t('product.category')}`"
    flat
  >
    <v-sheet class="pa-1">
      <v-text-field
        v-model="categorySearch"
        label="Search Categories..."
        flat
        prepend-inner-icon="mdi-magnify"
        solo
        hide-details
        clearable
        clear-icon="mdi-close"
        dense
        background-color="grey lighten-4"
      ></v-text-field>
    </v-sheet>
    <v-sheet tile height="34" class="pa-1">
      <v-chip small label dark color="grey lighten-1">
        {{
          selectedCategory && selectedCategory.section
            ? (selectedCategory.section
                ? selectedCategory.section.name + " / "
                : "") + selectedCategory.name
            : ""
        }}
      </v-chip>
    </v-sheet>
    <div style="overflow-y: scroll; height: 200px;">
      <v-treeview
        :items="sectionsFilter"
        item-children="categories"
        :active.sync="active"
        @update:active="$emit('input', selectedCategory)"
        hoverable
        activatable
        open-on-click
        transition
        dense
      >
        <template v-slot:prepend="{ selected }">
          <v-btn icon>
            <v-icon v-if="selected" small>
              mdi-label-multiple
            </v-icon>
            <v-icon v-if="!selected" small>
              mdi-label-multiple-outline
            </v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </div>
  </collapsable-card>
</template>

<script>
import CollapsableCard from "@/common/components/CollapsableCard";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CategorySelector",
  components: { CollapsableCard },
  props: {
    category: Object | null,
  },
  model: {
    prop: "category",
    event: "input",
  },
  data() {
    return {
      sections: [],
      categorySearch: null,
      active: [],
    };
  },
  mounted() {
    this.loadSectionsByParentId(this.getStoreIdParam).then((data) => {
      this.sections = data;
      if (this.category) {
        this.active = [this.category.id];
      }
    });
  },
  computed: {
    ...mapGetters("sections", ["getSectionsByParentId", "getSectionById"]),
    ...mapGetters("products", ["getStoreProducts"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },

    selectedCategory() {
      return this.notEmptyArray(this.getCategories) && this.active.length
        ? this.getCategories.find((c) => c.id === this.active[0])
        : null;
    },
    getCategories() {
      return [].concat(...this.sections.map(m => m.categories))
    },
    sectionsFilter() {
      if (!this.categorySearch || this.categorySearch.trim().length === 0) {
        return this.sections;
      }

      const categories =
        this.categorySearch && this.notEmptyArray(this.getCategories)
          ? this.getCategories.filter(
              (f) =>
                f.name
                  .toLowerCase()
                  .indexOf(this.categorySearch.toLowerCase()) !== -1
            )
          : [];

      const sections = this.sections.filter(
        (s) =>
          s.name.toLowerCase().indexOf(this.categorySearch.toLowerCase()) !==
            -1 ||
          (this.notEmptyArray(this.getCategories) &&
            this.getCategories
              .map((m) => (m.section ? m.section.id : 0))
              .includes(s.id))
      );

      return this.notEmptyArray(sections) && this.notEmptyArray(categories)
        ? sections.map((m) => ({
            ...m,
            categories: this.getCategories.filter(
              (f) => f.section && f.section.id === m.id
            ),
          }))
        : this.sections;
    },
  },
  methods: {
    ...mapActions("sections", ["loadSectionsByParentId"]),
  },
};
</script>
